import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import Cookies from 'js-cookie';
import absoluteUrl from 'next-absolute-url';
import App, { type AppContext, type AppInitialProps, type AppProps } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { ClientErrorBoundary, GlobalStyles, Layout, OnboardingPopup, ReportsPopup } from '@/components';
import { GeoProvider, useTrackSessions } from '@/hooks';
import { GlobalStateProvider } from '@/hooks/useGlobalState.hooks';

import {
  CONNECTING_TO_CONTENTFUL,
  CONNECTING_TO_REDIS,
  CookieKey,
  // ROUTES,
  // MAX_PHONE_WIDTH,
  DEVELOPMENT_HOST,
  EUCountryCodes,
  LocalStorageKey,
  NON_POPUP_PATHS,
  REDIS_APP_KEY_PREFIX,
  REDIS_PMC_APP_KEY_PREFIX,
  SlugRoutes,
  UTMKeys,
  serverSharedData,
  theme,
} from '@/constants';

import {
  checkIsPreviewModeRequest,
  checkTrackMyParcelPage,
  excludeUndefinedKey,
  getCarrierCollectionKey,
  getFileNameFromFileUrl,
  getReportPopupTypeByRouter,
  groupReportsPopupsByType,
  gtmVerificationPopupViews,
  gtmViewBrowserVerification,
  onboardingPopUpDataModify,
  parseCookie,
  reportsPopupDataModify,
  sendSentryError,
  setHasVisited,
  setLocalStorageByKey,
} from '@/utils';
import { removeCookies } from '@/utils/cookies.utils';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

import {
  getAuthenticatorAccessToken,
  getFooter,
  getHeader,
  getOnboardingPopUps,
  getPmtOnboardingPopUp,
  getUserInfo,
} from '@/fetchers';

import {
  type IFooter,
  type IHeader,
  type IReportsPopup,
  type IUserInfo,
  LanguageCode,
  OnboardingStepName,
} from '@/interfaces';

import { PmtSignUpStep } from '@/interfaces/tracking';
import { firebaseInit } from '@/lib';
import { loadDayjsLocale } from '@/utils';
import { pmtPopUpDataModify } from '@/utils/graphqlDataModify.utils';

const VerificationPopup = dynamic(async () => import('@/components/VerificationPopup'), { ssr: false });

const AdsProvider = dynamic(() => import('@/modules/pmt/context').then((mod) => mod.AdsProvider) as any, {
  ssr: false,
});

function MyApp({ Component, pageProps }: AppProps) {
  const {
    isLocalhost,
    userInfo,
    isLoggedIn,
    isTrackingPage,
    isMyParcelsPage,
    origin,
    header,
    footer,
    isErrorPage,
    onboardingPopUp,
    currentStep,
    webVersionCached,
    isWebpSupported,
    browserUuid,
    downloadFileNames,
    languageCode,
    headerInfo,
    authenticatorAccessToken,
    carriers,
  } = pageProps;

  console.debug(webVersionCached);

  if (headerInfo) {
    console.debug(headerInfo);
  }

  polyfillCountryFlagEmojis();

  const router = useRouter();
  const { asPath, pathname, query } = router;
  const { triggerGtmSessions } = useTrackSessions(isLoggedIn);

  useEffect(() => {
    setLocalStorageByKey(LocalStorageKey.authenticatorAccessToken, authenticatorAccessToken);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', removeCookies);
    return () => {
      window.removeEventListener('beforeunload', removeCookies);
    };
  }, []);

  useEffect(() => {
    document.documentElement.lang = languageCode;
    loadDayjsLocale(languageCode);
  }, [languageCode]);

  useEffect(() => {
    if (!userInfo) {
      Cookies.remove(CookieKey.UserId);
    }
  }, [userInfo]);

  useEffect(() => {
    // For track-my-parcel page, we handle in useEmailSyncAccounts
    // so that we can get the number of email accounts
    if (isMyParcelsPage && asPath.includes('/track-my-parcel')) return;
    triggerGtmSessions(isMyParcelsPage);
  }, [asPath]);

  const renderPopups = () => {
    const isNonPopupPath = NON_POPUP_PATHS.includes(pathname);
    const shouldNotRenderPopup = [isErrorPage, isNonPopupPath, isTrackingPage].some(Boolean);

    if (shouldNotRenderPopup) return null;
    const cookieVerificationStatus = Cookies.get(CookieKey.VerificationStatus);
    const differentBrowser = cookieVerificationStatus && JSON.parse(cookieVerificationStatus);
    if (differentBrowser) {
      isMyParcelsPage ? gtmViewBrowserVerification() : gtmVerificationPopupViews();
    }
    Cookies.remove(CookieKey.VerificationStatus);

    const reportsPopupType = getReportPopupTypeByRouter(query);
    const reportsPopups = reportsPopupType && header?.reportsPopup?.[reportsPopupType];
    const reportsPopup: IReportsPopup | undefined = reportsPopupDataModify(reportsPopups);

    const downloadFileName = reportsPopupType && JSON.parse(downloadFileNames || '{}')[reportsPopupType];
    const hasDownloadFileChange = getFileNameFromFileUrl(reportsPopup?.download.file?.url) !== downloadFileName;
    const shouldShowReportsPopup = reportsPopup && hasDownloadFileChange;

    return (
      <>
        {!isMyParcelsPage && onboardingPopUp && (
          <OnboardingPopup {...onboardingPopUp} differentBrowser={differentBrowser} />
        )}
        {shouldShowReportsPopup && <ReportsPopup key={reportsPopupType} {...reportsPopup} />}
        {differentBrowser && <VerificationPopup />}
      </>
    );
  };

  const GTMScriptComponent = () => {
    return (
      <>
        <Script
          id='gtag-consent'
          strategy='afterInteractive'
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `
              // Define dataLayer and the gtag function.
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}

              gtag('consent', 'default', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
              });
              
              gtag('consent', 'default', {
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'region': [
                  'US-CA',
                  ${EUCountryCodes.map((countryCode) => `'${countryCode}'`).join(',')}
                ],
              });
            `,
          }}
        />
        <Script
          id='gtm-init'
          strategy='afterInteractive'
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;

            j.addEventListener('load', function() {
              var _ge = new CustomEvent('gtm_loaded', { bubbles: true });
              d.dispatchEvent(_ge);
            });

            f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}');
          `,
          }}
        />
      </>
    );
  };

  const renderPageLayout = () => {
    const shouldRenderLayout = !NON_POPUP_PATHS.includes(pathname);
    if (!shouldRenderLayout) return <Component {...pageProps} />;
    return (
      <Layout footer={footer} header={header} onlyLogo={pageProps?.landingPageData?.onlyLogo}>
        <>
          <NextNProgress color={theme.colors.blue} options={{ showSpinner: false }} />
          {!isLocalhost && !isTrackingPage && <GTMScriptComponent />}
          <Component {...pageProps} />
        </>
      </Layout>
    );
  };

  firebaseInit();

  setHasVisited();

  const utmData: Record<UTMKeys, unknown> | null = excludeUndefinedKey({
    [UTMKeys.utmCampaign]: query[UTMKeys.utmCampaign],
    [UTMKeys.utmContent]: query[UTMKeys.utmContent],
    [UTMKeys.utmMedium]: query[UTMKeys.utmMedium],
    [UTMKeys.utmSource]: query[UTMKeys.utmSource],
    [UTMKeys.utmTerm]: query[UTMKeys.utmTerm],
  });

  useEffect(() => {
    const handleCaptureLoadingResourceError = (event: ErrorEvent) => {
      if (!event.target || typeof event.target !== 'object') {
        return;
      }

      const shouldIgnoreError = (nodeName: string, nodeAttributes: any) => {
        const CAPTURE_NODE_NAMES = ['img', 'script'];
        const isIgnoreError = !CAPTURE_NODE_NAMES.includes(nodeName.toLowerCase());

        if (isIgnoreError) {
          return true;
        }

        const isImage = nodeName.toLocaleLowerCase() === 'img';
        const isLazyLoading = isImage && nodeAttributes?.loading?.value === 'lazy';
        if (isLazyLoading) {
          return true;
        }

        const isHighPriority = isImage && nodeAttributes?.fetchpriority?.value === 'high';
        if (isHighPriority) {
          return true;
        }

        return false;
      };

      const target = event.target as any;
      const nodeAttributes = target.attributes;
      const nodeName = (event.target as Node).nodeName;
      const isIgnoreError = shouldIgnoreError(nodeName, nodeAttributes);
      if (isIgnoreError) {
        return;
      }

      const extraData: any = {
        nodeName,
      };

      Object.keys(nodeAttributes).forEach((key) => {
        extraData.attributes = {
          ...extraData.attributes,
          [nodeAttributes[key].name]: nodeAttributes[key].value,
        };
      });

      sendSentryError('Loading Resource 404s', extraData);
    };

    document.body.addEventListener(
      'error',
      handleCaptureLoadingResourceError,
      true, // useCapture - necessary for resource loading errors
    );

    return () => {
      document.body.removeEventListener('error', handleCaptureLoadingResourceError);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ClientErrorBoundary>
        <GlobalStateProvider
          origin={publicRuntimeConfig.origin || origin}
          isLoggedIn={isLoggedIn}
          userInfo={userInfo}
          isFollowingLinkedIn={true}
          showPopUp={false}
          showPMTPopUp={false}
          isFromGetNotifiedBtn={false}
          isFromEmailSyncBtn={false}
          isFromDelayedShipmentBtn={false}
          isFromJoinUsBtn={false}
          isFromBasicDetailsBtn={false}
          currentStep={currentStep}
          pmtSignUpStep={PmtSignUpStep.SignUp}
          isNextButtonClicked={false}
          isErrorPage={isErrorPage}
          isTrackingPage={isTrackingPage}
          aboutYouClicksLabel={onboardingPopUp?.aboutYouClicksLabel}
          isFollowUsZapierTriggered={false}
          isWebpSupported={isWebpSupported}
          browserUuid={browserUuid}
          utmData={utmData}
          carriers={carriers}
        >
          <GeoProvider>
            <AdsProvider>
              {renderPopups()}
              {renderPageLayout()}
            </AdsProvider>
          </GeoProvider>
        </GlobalStateProvider>
      </ClientErrorBoundary>
    </ThemeProvider>
  );
}

interface IAppServerSideProps {
  header: IHeader | null;
  footer: IFooter | null;
  onboardingPopUp: any;
  pmtOnboardingPopUp: any;
  pmtEmailSyncOnboardingPopUp: any;
}

MyApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
  let getServerPropsCached: (pageURL: string) => Promise<string | null> = (() => null) as any;
  let cacheServerProps: (pageURL: string, data: Record<string, any>) => void = () => null;

  if (!serverRuntimeConfig.useDev) {
    const redisUtils = await import('@/utils/redis.utils');

    getServerPropsCached = redisUtils.getServerPropsCached;
    cacheServerProps = redisUtils.cacheServerProps;
  }

  const { host } = absoluteUrl(appContext.ctx.req);
  const isWebpSupported =
    appContext.ctx.req?.headers['accept']?.split(',').includes('image/webp') ||
    appContext.ctx.req?.headers['accept'] === '*/*';

  const { ctx } = appContext;

  const isRssPage = ['/rss'].includes(ctx.pathname);
  if (isRssPage) {
    return { pageProps: null };
  }

  const isPreviewMode = checkIsPreviewModeRequest(ctx.query);

  const parsedCookie = parseCookie(ctx.req?.headers.cookie || '');
  const umsAccessToken: string | undefined =
    parsedCookie[CookieKey.UmsAccessToken] || parsedCookie[CookieKey.BrowserUuid];

  const [appProps, getUserInfoResponse, authenticatorAccessToken] = await Promise.all([
    App.getInitialProps(appContext),
    getUserInfo(umsAccessToken),
    getAuthenticatorAccessToken(),
  ]);

  const isUserInfoValid = getUserInfoResponse !== null && 'id' in getUserInfoResponse;
  const isLoggedIn = isUserInfoValid && !!getUserInfoResponse.verificationStatus;

  serverSharedData.isLoggedIn = isLoggedIn;
  serverSharedData.authenticatorAccessToken = authenticatorAccessToken;

  // This is for data protection
  let userInfo: IUserInfo | null = null;
  let currentStep: OnboardingStepName = OnboardingStepName.joinUs;

  if (isUserInfoValid) {
    userInfo = getUserInfoResponse;

    if (userInfo && isLoggedIn) {
      currentStep = OnboardingStepName.followUs;
    }
  }

  const pathNoParams = ctx.asPath?.split('?')[0];
  const { origin } = absoluteUrl(ctx.req);
  const isErrorPage = ['/404', '/500', '/503'].includes(ctx.pathname);
  const isTrackingPage = ['/tracking'].includes(ctx.pathname);
  const isMyParcelsPage = checkTrackMyParcelPage(ctx.asPath?.split('?')[0] || '').isTrackMyParcelPage;

  const isNonPopupPath = NON_POPUP_PATHS.includes(ctx.pathname);

  const appServerProps: IAppServerSideProps = {
    header: null,
    footer: null,
    onboardingPopUp: null,
    pmtOnboardingPopUp: null,
    pmtEmailSyncOnboardingPopUp: null,
  };

  //! TODO: BELOW IS TEMPORARY CONSOLE.LOG FOR TESTING CLOUDFLARE HEADER, DELETE LATER
  //! NOTE: console.log is enable for all env if matches condition
  const isPpUserFromGoogle =
    userInfo?.email.includes('@parcelperform.com') && userInfo.authenticationMethod === 'Google'; // no need interface since just testing
  const isSecretPath = ctx.asPath === '/events/packagefulfillmentlogisticsanddeliveryexpo';
  const headerInfo = isPpUserFromGoogle && isSecretPath && ctx.req?.headers;

  // ******* Get carriers from Redis *******
  let carriers = null;

  const shouldGetCarriers = pathNoParams?.includes(SlugRoutes.companies) || pathNoParams?.includes(SlugRoutes.markets);

  if (shouldGetCarriers) {
    carriers = JSON.parse((await getServerPropsCached(getCarrierCollectionKey())) || 'null');
  }
  // ***************************************

  const basePageProps = {
    pageProps: {
      ...(appProps as any).pageProps,
      ...appServerProps,
      isLocalhost: host === DEVELOPMENT_HOST,
      userInfo,
      isLoggedIn,
      origin,
      isErrorPage,
      isTrackingPage,
      isMyParcelsPage,
      currentStep,
      webVersionCached: CONNECTING_TO_CONTENTFUL,
      isWebpSupported,
      browserUuid: parsedCookie[CookieKey.BrowserUuid],
      downloadFileNames: parsedCookie[CookieKey.DownloadFileNames],
      languageCode: LanguageCode.English,
      headerInfo,
      authenticatorAccessToken,
      carriers,
    },
  };

  if (isNonPopupPath) {
    return basePageProps;
  }

  const { articleTypeSlug } = ctx.query;

  const languageCode = (isMyParcelsPage && (articleTypeSlug as LanguageCode)) || LanguageCode.English;
  const redisAppKey = isMyParcelsPage ? REDIS_APP_KEY_PREFIX + languageCode : REDIS_PMC_APP_KEY_PREFIX;
  basePageProps.pageProps.languageCode = languageCode;

  // Get header, footer, popup regardless of page url
  // app-en, app-zh-cn, app-zh-tw
  const serverPropsCached: IAppServerSideProps = JSON.parse((await getServerPropsCached(redisAppKey)) || 'null');
  if (serverPropsCached) {
    appServerProps.header = serverPropsCached.header;
    appServerProps.footer = serverPropsCached.footer;
    appServerProps.onboardingPopUp = serverPropsCached.onboardingPopUp;
    appServerProps.pmtOnboardingPopUp = serverPropsCached.pmtOnboardingPopUp;
    appServerProps.pmtEmailSyncOnboardingPopUp = serverPropsCached.pmtEmailSyncOnboardingPopUp;
    basePageProps.pageProps.webVersionCached = CONNECTING_TO_REDIS;
  } else {
    const inAppActions = [
      getHeader({ isPreviewMode, languageCode, slug: ctx.asPath?.split('?')[0] || 'app-header' }),
      getFooter({ isPreviewMode, languageCode, isMyParcelsPage, slug: ctx.asPath?.split('?')[0] || '/app-footer' }),
      isMyParcelsPage
        ? getPmtOnboardingPopUp({ isPreviewMode, languageCode })
        : getOnboardingPopUps({ isPreviewMode, slug: ctx.asPath?.split('?')[0] || 'app-popups' }),
    ];

    const inAppResponse: any = await Promise.all(inAppActions);
    appServerProps.header = inAppResponse[0];
    appServerProps.footer = inAppResponse[1];

    if (!isMyParcelsPage) {
      appServerProps.onboardingPopUp = inAppResponse[2];
    } else if (inAppResponse[2]) {
      appServerProps.pmtOnboardingPopUp = inAppResponse[2].pmtOnboardingPopUp;
      appServerProps.pmtEmailSyncOnboardingPopUp = inAppResponse[2].pmtEmailSyncOnboardingPopUp;
    }

    if (!isPreviewMode) {
      cacheServerProps(redisAppKey, appServerProps);
    }
  }

  if (!isMyParcelsPage) {
    appServerProps.onboardingPopUp = onboardingPopUpDataModify(appServerProps.onboardingPopUp);
    appServerProps.header = {
      ...appServerProps.header,
      reportsPopup: groupReportsPopupsByType(appServerProps.header?.reportsPopup),
    };
  } else {
    // Randomize pmtOnboardingPopUp for a/b testing
    appServerProps.pmtOnboardingPopUp = pmtPopUpDataModify(appServerProps.pmtOnboardingPopUp);
    appServerProps.pmtEmailSyncOnboardingPopUp = pmtPopUpDataModify(appServerProps.pmtEmailSyncOnboardingPopUp);
    delete appServerProps.header?.reportsPopup;
  }

  try {
    decodeURIComponent(ctx.asPath || '');
  } catch {
    return {
      pageProps: {
        ...basePageProps.pageProps,
        header: appServerProps.header,
        footer: null,
        errorCode: 404,
      },
    };
  }

  if (isErrorPage) {
    return {
      pageProps: {
        ...basePageProps.pageProps,
        header: appServerProps.header,
      },
    };
  }

  if (isTrackingPage) {
    return {
      pageProps: {
        ...basePageProps.pageProps,
        header: appServerProps.header,
        footer: appServerProps.footer,
      },
    };
  }

  return {
    pageProps: {
      ...basePageProps.pageProps,
      ...appServerProps,
    },
  };
};

export default MyApp;
