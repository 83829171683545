import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import {
  type Dispatch,
  type FC,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CookieKey, FALLBACK_PMT_LANGUAGE_CODE } from '@/constants';
import { type GetProperImageUrl, createGetProperImageUrl } from '@/fetchers/utils';
import useTranslations from '@/hooks/useTranslations.hook';
import {
  type IMinifiedCarrierByName,
  type IUserInfo,
  type IVerificationData,
  type LanguageCode,
  OnboardingStepName,
} from '@/interfaces';
import { PmtSignUpStep } from '@/interfaces/tracking';
import { checkTrackMyParcelPage } from '@/utils';
import { usePathname } from 'next/navigation';

interface IGlobalState {
  origin: string;
  pageUrl: string;
  isLoggedIn: boolean;
  userInfo: IUserInfo | null;
  isFollowingLinkedIn: boolean;
  showPopUp: boolean;
  showPMTPopUp: boolean;
  isHomepage: boolean;
  isErrorPage: boolean;
  isPrivacyPage: boolean;
  isTrackingPage: boolean;
  isMyParcelsPage: boolean;
  isWebpSupported: boolean;
  getProperImageUrl: GetProperImageUrl;
  browserUuid?: string | null;
  firestoreUuid?: string;
  utmData?: Record<string, unknown> | null;

  // For Reports Popup
  isFromReportsPopup?: boolean; // For Reports Popup Success

  // For PMT
  pmtCurrentLanguageCode: LanguageCode;
  pmtSignUpStep: PmtSignUpStep;
  isFromGetNotifiedBtn: boolean;
  isFromEmailSyncBtn: boolean;
  // TODO: @trungduong - move isFromDelayedShipmentBtn to PMT
  isFromDelayedShipmentBtn: boolean;

  // For Onboarding Popup
  isFromJoinUsBtn: boolean;
  isFromBasicDetailsBtn: boolean;
  currentStep: OnboardingStepName;
  isNextButtonClicked: boolean;
  aboutYouClicksLabel?: string;
  isFollowUsZapierTriggered: boolean;

  // For Verification Popup
  verificationData?: IVerificationData | null;

  // For Carriers Page
  carriers: IMinifiedCarrierByName | null;

  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setUserInfo: (userInfo: IUserInfo) => void;
  setIsFromJoinUsBtn: (isFromJoinUsBtn: boolean) => void;
  setIsFromBasicDetailsBtn: (isFromBasicDetailsBtn: boolean) => void;
  setCurrentStep: (currentStep: OnboardingStepName) => void;
  setIsFollowingLinkedIn: (isFollowingLinkedIn: boolean) => void;
  setShowPopUp: (showPopUp: boolean) => void;
  setShowPMTPopUp: (showPopUp: boolean) => void;
  setIsNextButtonClicked: (isNextButtonClicked: boolean) => void;
  setIsFollowUsZapierTriggered: (isFollowUsZapierTriggered: boolean) => void;
  setVerificationData: (verificationData: IVerificationData) => void;
  setFirestoreUuid: (firstoreUuid: string) => void;
  setCurrentPMTSignUpStep: (currentStep: PmtSignUpStep) => void;
  setIsFromGetNotifiedBtn: (isFromGetNotifiedBtn: boolean) => void;
  setIsFromEmailSyncBtn: (isFromEmailSyncBtn: boolean) => void;
  setIsFromDelayedShipmentBtn: (isFromDelayedShipmentBtn: boolean) => void;
  setIsFromReportsPopup: (isFromReportsPopup: boolean) => void;
  t: (key: string) => string;
}

const GlobalStateContext = createContext<IGlobalState>({
  origin: '',
  pageUrl: '',
  isLoggedIn: false,
  userInfo: null,
  isFollowingLinkedIn: true,
  showPopUp: false,
  showPMTPopUp: false,
  isHomepage: false,
  isErrorPage: false,
  isPrivacyPage: false,
  isTrackingPage: false,
  isMyParcelsPage: false,
  isWebpSupported: false,
  getProperImageUrl: (() => {}) as GetProperImageUrl,
  browserUuid: null,
  pmtCurrentLanguageCode: FALLBACK_PMT_LANGUAGE_CODE,
  isFromGetNotifiedBtn: false,
  isFromEmailSyncBtn: false,
  isFromDelayedShipmentBtn: false,
  isFromJoinUsBtn: false,
  isFromBasicDetailsBtn: false,
  currentStep: OnboardingStepName.joinUs,
  pmtSignUpStep: PmtSignUpStep.SignUp,
  isNextButtonClicked: false,
  isFollowUsZapierTriggered: false,
  verificationData: null,
  carriers: null,
  isFromReportsPopup: false,
  utmData: {},
  setIsLoggedIn: () => {},
  setUserInfo: () => {},
  setIsFromJoinUsBtn: () => {},
  setIsFromBasicDetailsBtn: () => {},
  setCurrentStep: () => {},
  setIsFollowingLinkedIn: () => {},
  setShowPopUp: () => {},
  setShowPMTPopUp: () => {},
  setIsNextButtonClicked: () => {},
  setIsFollowUsZapierTriggered: () => {},
  setVerificationData: () => {},
  setFirestoreUuid: () => {},
  t: () => '',
  setCurrentPMTSignUpStep: () => {},
  setIsFromGetNotifiedBtn: () => {},
  setIsFromEmailSyncBtn: () => {},
  setIsFromReportsPopup: () => {},
  setIsFromDelayedShipmentBtn: () => {},
});

// These props don't need to be passed to GlobalStateProvider component
type OmittedKeys =
  | 'setIsLoggedIn'
  | 'setUserInfo'
  | 'setIsFromJoinUsBtn'
  | 'setCurrentStep'
  | 'setIsFollowingLinkedIn'
  | 'setIsFromBasicDetailsBtn'
  | 'pageUrl'
  | 'isHomepage'
  | 'setIsNextButtonClicked'
  | 'setShowPopUp'
  | 'setShowPMTPopUp'
  | 'isPrivacyPage'
  | 'isMyParcelsPage'
  | 'pmtCurrentLanguageCode'
  | 'setIsSkipFollowUs'
  | 'setIsFollowUsZapierTriggered'
  | 'getProperImageUrl'
  | 'setVerificationData'
  | 'setFirestoreUuid'
  | 'setCurrentPMTSignUpStep'
  | 'setIsFromGetNotifiedBtn'
  | 'setIsFromEmailSyncBtn'
  | 'setIsFromReportsPopup'
  | 'setIsFromDelayedShipmentBtn'
  | 't';
type GlobalStateProviderProps = Omit<IGlobalState, OmittedKeys>;

// TODO: Khanh - modify to update nested data
type CreateSetProperty = (
  setGlobalState: Dispatch<SetStateAction<GlobalStateProviderProps>>,
) => <T>(key: keyof GlobalStateProviderProps) => (value: T) => void;

const createSetProperty: CreateSetProperty = (setGlobalState) => (key) => (value) => {
  setGlobalState((prevGlobalState) => ({
    ...prevGlobalState,
    [key]: value,
  }));
};

export const GlobalStateProvider: FC<GlobalStateProviderProps> = ({ children, ...restProps }) => {
  const [globalState, setGlobalState] = useState<GlobalStateProviderProps>(restProps);
  const { asPath, route } = useRouter();

  const createSetGlobalStateProperty = createSetProperty(setGlobalState);

  const setIsLoggedIn = createSetGlobalStateProperty<boolean>('isLoggedIn');
  const setUserInfo = createSetGlobalStateProperty<IUserInfo>('userInfo');
  const setIsFromJoinUsBtn = createSetGlobalStateProperty<boolean>('isFromJoinUsBtn');
  const setCurrentStep = createSetGlobalStateProperty<OnboardingStepName>('currentStep');
  const setCurrentPMTSignUpStep = createSetGlobalStateProperty<PmtSignUpStep>('pmtSignUpStep');
  const setIsFollowingLinkedIn = createSetGlobalStateProperty<boolean>('isFollowingLinkedIn');
  const setShowPopUp = createSetGlobalStateProperty<boolean>('showPopUp');
  const setShowPMTPopUp = createSetGlobalStateProperty<boolean>('showPMTPopUp');
  const setIsFromBasicDetailsBtn = createSetGlobalStateProperty<boolean>('isFromBasicDetailsBtn');
  const setIsNextButtonClicked = createSetGlobalStateProperty<boolean>('isNextButtonClicked');
  const setIsFollowUsZapierTriggered = createSetGlobalStateProperty<boolean>('isFollowUsZapierTriggered');
  const setVerificationData = createSetGlobalStateProperty<IVerificationData>('verificationData');
  const setFirestoreUuid = createSetGlobalStateProperty<string>('firestoreUuid');
  const setIsFromGetNotifiedBtn = createSetGlobalStateProperty<boolean>('isFromGetNotifiedBtn');
  const setIsFromEmailSyncBtn = createSetGlobalStateProperty<boolean>('isFromEmailSyncBtn');
  const setIsFromReportsPopup = createSetGlobalStateProperty<boolean>('isFromReportsPopup');
  const setIsFromDelayedShipmentBtn = createSetGlobalStateProperty<boolean>('isFromDelayedShipmentBtn');

  const handler = (e: MessageEvent): void => {
    if (e.origin === 'https://www.linkedin.com') {
      const parsedData = e.data && JSON.parse(e.data);

      if (parsedData.method !== 'resize') return;

      if (parsedData.params[0].width === 0) return;

      const isFollowingButton = parsedData.params[0].width > 90;
      setIsFollowingLinkedIn(isFollowingButton);
      Cookies.set(CookieKey.IsFollowing, isFollowingButton);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const getProperImageUrl = useMemo(() => createGetProperImageUrl(globalState.isWebpSupported), []);

  const pathNoParams = usePathname();

  const isMyParcelsPage = checkTrackMyParcelPage(pathNoParams).isTrackMyParcelPage;
  const pmtCurrentLanguageCode = checkTrackMyParcelPage(pathNoParams).languageCode;
  const t = useTranslations(isMyParcelsPage ? pmtCurrentLanguageCode : FALLBACK_PMT_LANGUAGE_CODE);

  const value = useMemo(
    () => ({
      ...globalState,
      pageUrl: globalState.origin + asPath,
      isHomepage: route === '/',
      isErrorPage: ['/404', '/500', '/503'].includes(route),
      isPrivacyPage: pathNoParams === '/privacy',
      isTrackingPage: pathNoParams === '/tracking',
      firestoreUuid: Cookies.get(CookieKey.UserId) || Cookies.get(CookieKey.GuestTrackingUuid),
      isMyParcelsPage,
      pmtCurrentLanguageCode,
      getProperImageUrl,
      setIsLoggedIn,
      setUserInfo,
      setIsFromJoinUsBtn,
      setCurrentStep,
      setIsFollowingLinkedIn,
      setShowPopUp,
      setShowPMTPopUp,
      setIsFromBasicDetailsBtn,
      setIsNextButtonClicked,
      setIsFollowUsZapierTriggered,
      setVerificationData,
      setFirestoreUuid,
      setCurrentPMTSignUpStep,
      setIsFromGetNotifiedBtn,
      setIsFromEmailSyncBtn,
      setIsFromReportsPopup,
      setIsFromDelayedShipmentBtn,
      t,
    }),
    [globalState, asPath, route, t],
  );

  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
};

const useGlobalState = (): IGlobalState => useContext(GlobalStateContext);

export default useGlobalState;
